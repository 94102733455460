.product-card {
  display: flex;
  flex-wrap: wrap;

  .product-card__btn-toolbar {
    margin-bottom: 10px;
  }
}

.product-card__info {
  width: calc(100% - 440px);
  @include directify($directions) {
    #{directed('padding-left')}: 42px;
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    @include directify($directions) {
      #{directed('padding-left')}: 0px;
    }
    padding-top: 35px;
  }
}

.product-card__wish-btn {
  display: block;
  padding: 10px 0;
  font-size: 14px;
  color: $color-blue;
  height: 42px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    height: 14px;
    fill: $color-blue;
    transition: all 0.3s;
  }

  &:hover {
    color: $color-blue-hover;

    svg {
      fill: $color-blue-hover;
    }
  }
}

.product-card__title {
  @include directify($directions) {
    text-align: directed('left');
  }
  font-weight: 700;
  margin-bottom: 10px;
}

.product-card__rate {
  display: flex;
  margin-bottom: 30px;

  svg {
    fill: $color-yellow;
    height: 14px;
    width: 14px;
  }
}

.product-card__link {
  font-size: 12px;
  color: $color-blue;
  line-height: 16px;
  display: block;
  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  &:hover {
    color: $color-blue-hover;
    text-decoration: none;
  }
}

.product-card__price {
  margin-bottom: 25px;
  @include directify($directions) {
    text-align: directed('left');
  }
}

.product-card__old-price {
  font-size: 24px;
  color: $color-additional;
  text-decoration: line-through;
}

.product-card__form {
  margin-top: 20px;
}

.product-card__form-label {
  font-weight: 500;
}
